<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <h2 class="mb-5">Reactions of Copper - Post-Lab Question 1</h2>

      <p class="mb-n3">
        a) In the second step of the reaction, when you added nitric acid to the copper wire, what
        gas was produced?
      </p>

      <v-radio-group v-model="inputs.gasFormed" class="ml-6 mb-0" :disabled="!allowEditing">
        <v-radio
          v-for="option in optionsGasFormed"
          :key="'pt-1-' + option.value"
          class="my-1"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>

      <p class="mb-n3">
        b) What type of reaction is the digestion of solid copper wire by nitric acid?
      </p>

      <v-radio-group v-model="inputs.digestionRxnType" class="ml-6 mb-0" :disabled="!allowEditing">
        <v-radio
          v-for="option in optionsDigestionRxnType"
          :key="'pt-2-' + option.value"
          class="my-1"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>

      <p class="mb-2">
        c) After adding the sulfuric acid and letting that reaction proceed to completion, what
        chemical species were left in the beaker?
      </p>

      <p v-for="option in optionsSpeciesInBeaker" :key="'pt-3-' + option.value" class="mb-0 mt-0">
        <v-checkbox v-model="inputs.speciesInBeaker" :value="option.value" class="ml-6 mb-n4 mt-0">
          <template v-slot:label>
            <stemble-latex :content="option.text" />
          </template>
        </v-checkbox>
      </p>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'OleMissPostLab8MC',
  components: {StembleLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        gasFormed: null,
        digestionRxnType: null,
        speciesInBeaker: [],
      },
      optionsGasFormed: [
        {text: 'Mononitrogen dioxide', value: 'NO'},
        {text: 'Nitric acid', value: 'HNO3'},
        {text: 'Nitrogen dioxide', value: 'NO2'},
        {text: 'Nitrous oxide', value: 'N2O'},
        {text: 'Nitrogen', value: 'N2'},
      ],
      optionsDigestionRxnType: [
        {text: 'Double displacement', value: 'doubleDisplacement'},
        {text: 'Hydration', value: 'hydration'},
        {text: 'Redox', value: 'redox'},
        {text: 'Combustion', value: 'combustion'},
      ],
      optionsSpeciesInBeaker: [
        {text: 'copper(II) sulfate', value: 'CuSO4'},
        {text: 'copper(II) nitrate', value: 'CuNO3'},
        {text: 'copper(II) oxide', value: 'CuO'},
        {text: 'copper(II) hydroxide', value: 'CuOH'},
        {text: 'Cu metal', value: 'Cu'},
        {text: 'Zn metal', value: 'Zn'},
        {text: 'Water', value: 'H2O'},
      ],
    };
  },
};
</script>
